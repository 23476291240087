import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { Link } from 'react-router-dom';
import en from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_English.pdf';
import ena from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR_English.pdf';
import as from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Asamese.pdf';
import asa from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR_Assamese.pdf';
import be from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Bengali.pdf';
import bea from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR _Bengali.pdf';
import te from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Telugu.pdf';
import tea from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR _Telugu.pdf';
import ma from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Malayalam.pdf';
import maa from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR _ Malayalam.pdf';
import pu from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Punjabi.pdf';
import pua from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR_Punjabi.pdf';
import gu from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Gujarati.pdf';
import gua from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR_Gujarati.pdf';
import hi from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Hindi.pdf';
import hia from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR_Hindi.pdf';
import ka from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Kannada.pdf';
import kaa from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR_Kannada.pdf';
import mar from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Marathi.pdf';
import mara from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR _Marathi.pdf';
import od from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Odiya.pdf';
import oda from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR_Odiya.pdf';
import ta from '../../assests/sgPdf/17.policies/Fair Practice Code/Fair Practice Code_Tamil.pdf';
import taa from '../../assests/sgPdf/17.policies/Annexure-B_Fair Practice Code/Annexure-B_FPC_GR_Tamil.pdf';

const PolicyLanguage = () => {

    const [selectedOption, setSelectedOption] = useState("option0"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Fair Practice Code </h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Policy - Fair Practice Code </h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option0">Select Language</option>
                                <option value="option">Assamese</option>
                                <option value="option1">Bengali</option>
                                <option value="option2">English</option>
                                <option value="option3">Gujarati</option>
                                <option value="option4">Hindi</option>
                                <option value="option5">Kannada</option>
                                <option value="option6">Malayalam</option>
                                <option value="option7">Marathi</option>
                                <option value="option8">Odiya</option>
                                <option value="option9">Punjabi</option>
                                <option value="option10">Tamil</option>
                                <option value="option11">Telugu</option>


                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                        {selectedOption === "option" && (
                            <>
                                
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={as} target='blank'>  <h6>Fair Practice Code_Assamese</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={asa} target='blank'>  <h6> Annexure-B_Fair Practice Code_Assamese</h6> </a>
                            </div>
                                

                            </>

                        )}




                        {selectedOption === "option1" && (
                            <>

                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={be} target='blank'>  <h6>Fair Practice Code_Bengali</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={bea} target='blank'>  <h6> Annexure-B_Fair Practice Code_Bengali</h6> </a>
                            </div>


                              
                            </>
                        )}
                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={en} target='blank'>  <h6>Fair Practice Code</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={ena} target='blank'>  <h6> Annexure-B_Fair Practice Code_English</h6> </a>
                                </div>
                                
                            </>

                        )}
                        
                        {selectedOption === "option3" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={gu} target='blank'>  <h6>Fair Practice Code_Gujrati</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={gua} target='blank'>  <h6> Annexure-B_Fair Practice Code_gujrati</h6> </a>
                            </div>
                                
                            </>
                        )}
                        {selectedOption === "option4" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={hi} target='blank'>  <h6>Fair Practice Code_Hindi</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={hia} target='blank'>  <h6> Annexure-B_Fair Practice Code_Hindi</h6> </a>
                            </div>
                            </>
                        )}
                        {selectedOption === "option5" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={ka} target='blank'>  <h6>Fair Practice Code_Kannada</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={kaa} target='blank'>  <h6> Annexure-B_Fair Practice Code_Kannada</h6> </a>
                            </div>
                            </>
                        )}
                        {selectedOption === "option6" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={ma} target='blank'>  <h6>Fair Practice Code_Malayalam</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={maa} target='blank'>  <h6> Annexure-B_Fair Practice Code_Malayalam</h6> </a>
                            </div>
                            </>
                        )}
                        {selectedOption === "option7" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={mar} target='blank'>  <h6>Fair Practice Code_Marathi</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={mara} target='blank'>  <h6> Annexure-B_Fair Practice Code_Marathi</h6> </a>
                            </div>
                            </>
                        )}
                        {selectedOption === "option8" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={od} target='blank'>  <h6>Fair Practice Code_Odiya</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={oda} target='blank'>  <h6> Annexure-B_Fair Practice Code_Odiya</h6> </a>
                            </div>
                            </>
                        )}
                        {selectedOption === "option9" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={pu} target='blank'>  <h6>Fair Practice Code_Punjabi</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={pua} target='blank'>  <h6> Annexure-B_Fair Practice Code_Punjabi</h6> </a>
                            </div>
                            </>
                        )}
                        {selectedOption === "option10" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={ta} target='blank'>  <h6>Fair Practice Code_Tamil</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={taa} target='blank'>  <h6> Annexure-B_Fair Practice Code_Tamil</h6> </a>
                            </div>
                            </>
                        )}
                        {selectedOption === "option11" && (
                            <>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={te} target='blank'>  <h6>Fair Practice Code_Telugu</h6> </a>
                            </div>
                            <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={tea} target='blank'>  <h6> Annexure-B_Fair Practice Code_Telugu</h6> </a>
                            </div>
                            </>
                        )}
                        
                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}




export default PolicyLanguage
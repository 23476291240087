import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import DotRing from '../../component/dot/DotRing';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import { FaFileVideo } from "react-icons/fa";
import bd4 from '../../assests/sgPdf/List of Committees w.e.f.  19.11.2024 (1).pdf';
import FY24_25 from '../../assests/sgPdf/Annual Report/j. FY 2024-25/30th annual report of the company.pdf';
import FY24_25a from '../../assests/sgPdf/Annual Report/j. FY 2024-25/Proceedings of the 30th Annual General Meeting-SGFL.pdf';
import FY24_25b from '../../assests/sgPdf/Annual Report/j. FY 2024-25/Voting Result of 30th Annual General Meeting.pdf';
import FY24_25c from '../../assests/sgPdf/Annual Report/j. FY 2024-25/Postal Ballot Results 05.10.2024.pdf';
import FY24_25d from '../../assests/sgPdf/Annual Report/j. FY 2024-25/Scrutnizer Report_31.12.2024.pdf';
import FY23_24 from '../../assests/sgPdf/Annual Report/i. FY 2023-24/29th Annual Report.pdf';
import FY22_23 from '../../assests/sgPdf/Annual Report/ii. FY 2022-23/28th Annual Report of MSL.pdf';
import FY21_22 from '../../assests/sgPdf/Annual Report/iii. FY 2021-22/31.03.2022.pdf';
import FY21_22a from '../../assests/sgPdf/Annual Report/iii. FY 2021-22/27th Annual Report of MSL.pdf';
import FY20_21 from '../../assests/sgPdf/Annual Report/iv. FY 2020-21/31.03.2021.pdf';
import FY19_20 from '../../assests/sgPdf/Annual Report/v. FY 2019-20/31.03.2020.pdf';
import FY18_19 from '../../assests/sgPdf/Annual Report/vi. FY 2018-19/31.03.2019.pdf';
import FY17_18 from '../../assests/sgPdf/Annual Report/vii. FY 2017-18/31.03.2018.pdf';
import FY16_17 from '../../assests/sgPdf/Annual Report/viii. FY 2016-17/31.03.2017.pdf';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const NonConvertible = () => {

    return (
        <>

            <DotRing />

            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    backgroundImage: `url(${investerBanner})`,
                }}>
                    <div className="container">
                        <h2>Non-Convertible Debt Securities</h2>
                    </div>
                </div>
            </section>


            <div className='main_investor_containner'>
                <h3 style={{ textAlign: 'center' }}>Non-Convertible Debt Securities</h3>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '840px' }}>

                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>a. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	Name of the Debenture Trustees with full contact details </p>
                            </div>
                            <Link>  <button className='investor_button'> click here </button></Link>
                        </div>
                        <div className='investor_row_data'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>b. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}>  The information, report, notices, call letters, circulars, proceedings, etc concerning Non-Convertible Redeemable Preference Shares or Non-Convertible Debt Securities </p>
                            </div>
                            <Link>  <button className='investor_button'> click here </button></Link>
                        </div>
                        

                        

                        <div className='investor_row_data-fina'>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <span>c. </span>
                                <p className='text_data_investor' style={{ margin: '0' }}> 	All information and reports including compliance reports filed by the listed entity</p>
                            </div>

                            <ul style={{ marginTop: '20px', justifyContent: 'space-between' }}>
                                <li>Default by issuer to pay interest or redemption amount
                                <a style={{float:"right"}}>  <button className='investor_button'>NA</button></a></li>
                                <br />
                                <li>Failure to create a charge on the assets
                                <a style={{float:"right"}}>  <button className='investor_button'>NA</button></a></li>
                            </ul>


                        </div>


                        
                        



                    </div>
                </div>
            </div >

        
            


            <Footer />
        </>
    )
}

export default NonConvertible
import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import Navbar from "./Navbar";
import Footer from "./Footer";
import contactBanner from "../assests/complaint/complaint.webp";
import Careers from '../component/Careers';

import { FloatingWhatsApp } from 'react-floating-whatsapp';
import DotRing from '../component/dot/DotRing';
import '../hamcss/contact.css';
import bg from '../assests/contact/contactBG.webp';
import '../hamcss/contact.css';

const Complaint = () => {
  useEffect(() => {
    AOS.init();
  });

  return (

    <>


      <div>
        <Navbar />
        <DotRing />

        {/* <!--start banner slider--> */}
        <section>
          <div
            className="BreadArea"
            style={{
              backgroundImage: `url(${contactBanner})`,
              // backgroundImage: "url('images/Bread.png')",
            }}
          >
            <div className="container">

              <h2 className="shadows">
                <span>C</span>
                <span>O</span>
                <span>M</span>
                <span>P</span>
                <span>L</span>
                <span>A</span>
                <span>I</span>
                <span>N</span>
                <span>T</span>
                <span>/</span>
                <span>G</span>
                <span>R</span>
                <span>I</span>
                <span>E</span>
                <span>V</span>
                <span>A</span>
                <span>N</span>
                <span>C</span>
                <span>E</span>
                <span>S</span>
              </h2>

            </div>
          </div>
        </section>
      </div>

      <section style={{ display: 'flex', justifyContent: 'center', }}>
        <section className="containner">

          <section className='main-wraper-cl' >
            <div className="main-con-cl">
              <div class="col-sm-6">
                <h3 className="headings">CUSTOMERS COMPLAINTS</h3>
                <div class="single-footer-widget" >
                <div className="info-contact">
                  
                    <i className="fa fa-user"></i>
                    <span>
                        Mr. Anshul Manchanda - Grievance Redressal Officer
                    </span>
                    

                  </div>
                  <div className="info-contact">

                    <i className="fa fa-phone"></i>
                    <span>
                        <a href="tel:8586944917">8586944917</a>
                    </span>
                    

                  </div>
                  <div className="info-contact">
                      <i className="fa fa-envelope"></i>
                      <span>anshul.manchanda@sgfinserve.com</span>
                  </div>
                  <div className="info-contact">
                      
                      <span>(From 10.00 am To 6:00 pm, Monday – Friday, except on public holidays)</span>
                      
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <h3 className="headings">INVESTORS COMPLAINTS</h3>
                <div class="single-footer-widget" >
                  <div className="info-contact">
                    <i className="fa fa-user"></i>
                    <span>
                        Ms. Ritu Nagpal - Company Secretary & Compliance Officer
                    </span>
                    

                  </div>
                  <div className="info-contact">
                    <i className="fa fa-phone"></i>
                    <span>
                        <a href="tel:0120-4041400">0120-4041400</a>
                    </span>
                    

                  </div>
                  <div className="info-contact">
                      <i className="fa fa-envelope"></i>
                      <span>compliance@sgfinserve.com</span>
                  </div>
                  <div className="info-contact">
                      <span>(From 10.00 am To 6:00 pm, Monday – Friday, except on public holidays)</span>
                      
                  </div>
                </div>
               
              </div>
            </div>
          </section>


          <Footer />


        </section>
      </section >
    </>
  );
};

export default Complaint;

import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";

import p1 from '../../assests/sgPdf/17.policies/1. Risk Management Policy-Amended.pdf';
import p2 from '../../assests/sgPdf/17.policies/1. Risk Management Policy.pdf';
import p3 from '../../assests/sgPdf/17.policies/2. Interest Rate Policy.pdf';
import p4 from '../../assests/sgPdf/17.policies/3. Outsourcing Policy.pdf';
import p5 from '../../assests/sgPdf/17.policies/4. Sexual harrasment Policy.pdf';
import p6_ from '../../assests/sgPdf/17.policies/6. Ombudsman Scheme.pdf';
import p6 from '../../assests/sgPdf/17.policies/Ombudsman Scheme.pdf';
import p7 from '../../assests/sgPdf/17.policies/Grievance Redressal Policy_Annexure B of FPC.pdf';
import p8 from '../../assests/sgPdf/17.policies/8. Fair Practice Code.pdf';
import p9 from '../../assests/sgPdf/17.policies/9. Corporate Governance.pdf';
import p10 from '../../assests/sgPdf/17.policies/10. ALM Policy.pdf';
import p11 from '../../assests/sgPdf/17.policies/11. Liquidity Risk Management Policy.pdf';
import p12 from '../../assests/sgPdf/17.policies/12. Credit Policy.pdf';
import p13 from '../../assests/sgPdf/17.policies/Archival policy.pdf';
import p14 from '../../assests/sgPdf/17.policies/FOR FAIR DISCLOSURE OF UNPUBLISHED PRICE.pdf';
import p15 from '../../assests/sgPdf/17.policies/Nomination & Remuneration Policy.pdf';
// import p16 from '../../assests/sgPdf/17.policies/Policy for determination of materiality of event.pdf';
import p17 from '../../assests/sgPdf/17.policies/Policy for preservation of documents.pdf';
import p18 from '../../assests/sgPdf/17.policies/Policy on Materiality of Related Party Transactions.pdf';
import p19 from '../../assests/sgPdf/17.policies/Risk mamagement Policy.pdf';
import p20 from '../../assests/sgPdf/17.policies/RPT Policy SGFIN w.e.f. 25.01.2023.pdf';
import p21 from '../../assests/sgPdf/17.policies/Whistle Blower Policy.pdf';
import p22 from '../../assests/sgPdf/17.policies/IRAC disclosure on website.pdf';
import p23 from '../../assests/sgPdf/17.policies/Draft process for return of original documents.pdf';
import p24 from '../../assests/sgPdf/17.policies/annexure-1.pdf';
import p25 from '../../assests/sgPdf/17.policies/Annexure-2.pdf';
import p26 from '../../assests/sgPdf/17.policies/Annexure-3.pdf';
import p27 from '../../assests/sgPdf/17.policies/Digital Lending Policy 2.pdf';
import p28 from '../../assests/sgPdf/17.policies/Investment Policy.pdf';
import p29 from '../../assests/sgPdf/17.policies/IT-Policy Part-I.pdf';
import p30 from '../../assests/sgPdf/17.policies/IT-Policy Part-II.pdf';
import p31 from '../../assests/sgPdf/17.policies/Loan Policy.pdf';
import p32 from '../../assests/sgPdf/17.policies/Outsorcing Policy.pdf';
import p33 from '../../assests/sgPdf/17.policies/Policy on Archival of Documents.pdf';
import p34 from '../../assests/sgPdf/17.policies/Policy on Fit and Proper criteria 2.pdf';
import p36 from '../../assests/sgPdf/17.policies/IT Outsourcing Policy.pdf';
import p37 from '../../assests/sgPdf/17.policies/AML KYC Policy.pdf';
import p38 from '../../assests/sgPdf/17.policies/Digital Lending Policy.pdf';
import p39 from '../../assests/sgPdf/17.policies/Fair Practice Code.pdf';
import p40 from '../../assests/sgPdf/17.policies/Interest Rate Policy.pdf';
import p41 from '../../assests/sgPdf/17.policies/IT Outsourcing Policy.pdf';
import p42 from '../../assests/sgPdf/17.policies/Liquidity Risk Management Policy.pdf';
import p43 from '../../assests/sgPdf/17.policies/Ombudsman Scheme_ Annexure A of FPC.pdf';
import p44 from '../../assests/sgPdf/17.policies/Policy on Fit and Proper criteria.pdf';
import p45 from '../../assests/sgPdf/17.policies/Policy on Preservation of Documents 2.pdf';
import p46 from '../../assests/sgPdf/17.policies/Policy on Related Party Transactions.pdf';
import p47 from '../../assests/sgPdf/17.policies/Appointment of Statutory Auditor.pdf';
import p48 from '../../assests/sgPdf/17.policies/Sechdule of Charges.pdf';
import p49 from '../../assests/sgPdf/17.policies/Draft process for return of original documents_.pdf';
import p50 from '../../assests/sgPdf/17.policies/RPT Policy.pdf';
import p51 from '../../assests/sgPdf/17.policies/Nomination and Remuneration Policy_1.02.pdf';
import p52 from '../../assests/sgPdf/17.policies/Corporate Governance Policy.pdf';
import p53 from '../../assests/sgPdf/17.policies/Business Continuity Planning Policy.pdf';
import p54 from '../../assests/sgPdf/17.policies/Change and Patch Management Policy.pdf';
import p55 from '../../assests/sgPdf/17.policies/Cloud Adoption Policy.pdf';
import p56 from '../../assests/sgPdf/17.policies/Cyber Security Policy.pdf';
import p57 from '../../assests/sgPdf/17.policies/Data Migration Policy.pdf';
import p58 from '../../assests/sgPdf/17.policies/Information Asset Policy.pdf';
import p59 from '../../assests/sgPdf/17.policies/Information Security Policy.pdf';
import p60 from '../../assests/sgPdf/17.policies/Information System (IS) Audit Policy.pdf';
import p61 from '../../assests/sgPdf/17.policies/Information Technology Policy.pdf';
import p62 from '../../assests/sgPdf/17.policies/Outsourcing of IT Services Policy.pdf';
import p63 from '../../assests/sgPdf/17.policies/Vulnerability Assessment and Penetration Testing Policy.pdf';
import p64 from '../../assests/sgPdf/17.policies/Policy on determining material subsidiary.pdf';
import p65 from '../../assests/sgPdf/17.policies/Archival of documents policy.pdf';
import p66 from '../../assests/sgPdf/17.policies/Code of Conduct for Directors & Senior management.pdf';
import p67 from '../../assests/sgPdf/17.policies/Code of conduct on insider trading.pdf';
import p68 from '../../assests/sgPdf/17.policies/Loan Charges-Penal charge policy.pdf';
import p69 from '../../assests/sgPdf/17.policies/Outsourcing Policy.pdf';
import p70 from '../../assests/sgPdf/17.policies/Policy on determination of material events or information.pdf';
import p71 from '../../assests/sgPdf/17.policies/SGBLR_SGFLs Benchmark Interest Rate.pdf';
import p72 from '../../assests/sgPdf/17.policies/Policy for dealing with Natural Calamities.pdf';
import p73 from '../../assests/sgPdf/17.policies/Income Recognition & Asset Classification Policy.pdf';
import p74 from '../../assests/sgPdf/17.policies/Customer education on SMA_NPA.pdf';
import p75 from '../../assests/sgPdf/17.policies/Schedule of charges.pdf';
import p76 from '../../assests/sgPdf/17.policies/Nomination and Remuneration Policy.pdf';


const Policies = () => {



    const [selectedOption, setSelectedOption] = useState("option1"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>


            <Navbar />
            <section>
                <div className="BreadArea" style={{
                    //  for /beta server 
                    backgroundImage: `url(${investerBanner})`,
                    // backgroundImage: "url('images/Bread.png')",
                }}>
                    <div className="container">
                        <h2>Policy</h2>

                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Policy</h2>

                        <div>

                        <a class="btn btn-info" href="/policy-language">Fair Practice Code</a>
                            {/* <select className="select-class" onChange={handleOptionChange}>
                                <option value="option1">Option 1</option>
                                <option value="option2">Option 2</option>
                                <option value="option3">Option 3</option>
                                <option value="option4">Option 4</option>
                                <option value="option5">Option 5</option>
                                <option value="option6">Option 6</option>
                            </select> */}
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {/* Conditionally render PDFs based on selected option */}
                        {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p2} target='blank'>  <h6> Risk Management Policy.pdf</h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p40} target='blank'>  <h6> Interest Rate Policy.pdf</h6> </a>
                                </div>

                                

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p5} target='blank'>  <h6>Sexual harrasment Policy.pdf </h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p6} target='blank'>  <h6> Ombudsman Scheme</h6> </a>
                                </div>
                                

                                



                                

                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p75} target='blank'>  <h6> Schedule of charges</h6> </a>
                                </div>

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p42} target='blank'>  <h6> Liquidity Risk Management Policy.pdf</h6> </a>
                                </div>

                                



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p10} target='blank'>  <h6> ALM Policy.pdf</h6> </a>
                                </div>



                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p28} target='blank'>  <h6> Investment Policy</h6> </a>
                                </div>

                                

                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p44} target='blank'>  <h6>Policy on Fit and Proper Criteria </h6> </a>
                                </div>

                                


                                



                                



                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p65} target='blank'>  <h6>Policy on archival of documents</h6> </a>
                                </div>


                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p45} target='blank'>  <h6>Policy for Preservation of Documents</h6> </a>
                                </div>


                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p21} target='blank'>  <h6> Whistle Blower Policy.pdf</h6> </a>
                                </div>


                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p38} target='blank'>  <h6>Digital Lending policy</h6> </a>
                                </div>



                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p64} target='blank'>  <h6>Policy on determining material subsidiary</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p74} target='blank'>  <h6> Customer Education on SMA/NPA</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p49} target='blank'>  <h6> Process for release of original property documents in case of death of property owner</h6> </a>
                                </div>
                                
                                
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p37} target='blank'>  <h6> AML KYC Policy.pdf</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p47} target='blank'>  <h6> Policy For Appointment of Statutory Auditors</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p76} target='blank'>  <h6> Nomination & Remuneration Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p50} target='blank'>  <h6> RPT Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p52} target='blank'>  <h6> Corporate Governance Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p53} target='blank'>  <h6> Business Continuity Planning Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p54} target='blank'>  <h6> Change and Patch Management Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p55} target='blank'>  <h6> Cloud Adoption Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p56} target='blank'>  <h6> Cyber Security Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p57} target='blank'>  <h6> Data Migration Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p58} target='blank'>  <h6> Information Asset Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p59} target='blank'>  <h6> Information Security Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p60} target='blank'>  <h6> Information System (IS) Audit Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p61} target='blank'>  <h6> Information Technology Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p62} target='blank'>  <h6> Outsourcing of IT Services Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p63} target='blank'>  <h6> Vulnerability Assessment and Penetration Testing Policy</h6> </a>
                                </div>
                                
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p67} target='blank'>  <h6> Code of conduct on insider trading</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p68} target='blank'>  <h6> Loan Charges-Penal charge policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p69} target='blank'>  <h6> Outsourcing Policy</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p70} target='blank'>  <h6> Policy on determination of material events or information</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p71} target='blank'>  <h6> SGBLR_SGFL's Benchmark Interest Rate</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p72} target='blank'>  <h6> Policy for dealing with Natural Calamities</h6> </a>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={p73} target='blank'>  <h6> Income Recognition & Asset Classification Policy</h6> </a>
                                </div>
                                


                                
                                
                                
                                
                                
                                
                                
                                
                                

                                














                            </>

                        )}
                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                        {selectedOption === "option4" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                        {selectedOption === "option5" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                        {selectedOption === "option6" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas-2">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <h6>PDF Name for Option 1</h6>
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}


export default Policies
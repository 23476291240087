import React from 'react'
import Navbar from '../Navbar';
import Solution_cards from '../solution/Solution_cards';
import Footer from '../Footer';
import '../../hamcss/dealer.css';
import { useEffect } from 'react';
import AOS from 'aos';
import logistic from '../../assests/home/top_banner/logistic_finance.webp';


import { FloatingWhatsApp } from 'react-floating-whatsapp';

const Transporter = () => {

    useEffect(() => {
        AOS.init();

    })

    return (
        <>
            <div className='main_dealer_container' >
                <Navbar />
                {/* <!--start banner slider--> */}
                <section>
                    <div className="BreadArea" style={{
                        // for /beta server
                        backgroundImage: `url(${logistic})`,
                        // backgroundImage: "url('images/Bread.png')",
                    }}>
                        <div data-aos="fade-down" data-aos-duration='1000' className="container">
                            <h2 style={{ wordSpacing: '2px', letterSpacing: '0.2px' }}>Logistics/ Transporter Finance </h2>


                        </div>
                    </div>
                </section>
                <div className='dealer_child_box' >
                    <div className='dealer_text_container'>
                        <h1 style={{ wordSpacing: '2px', letterSpacing: '0.2px', fontWeight: '600' }} className='dealer_text_heading_2' >What is  Logistics/ Transporter Financing?</h1>
                        <p className='dealer_text_para'>Logistics/ Transport Finance sits at the heart of our end-to-end Logistics offering, supporting & empowering transport companies.
                            Uncover inefficient supply chain operations and allow SGFL to finance your freight/ transport invoices.
                           
                        </p>

                        <p className='dealer_text_para'>Logistics or transporter financing is a specific type of supply chain finance tailored to
                            meet the financial needs of logistics companies, freight carriers, and transporters.
                            This form of financing helps these businesses manage their cash flow, invest in fleet
                            maintenance and expansion, and navigate the challenges of operating in the
                            logistics industry.
                        </p>

                        <div>
                            <h4 style={{ textAlign: "left" }}> Here&#39;s a brief overview of logistics/transporter financing product offered by SG
                                Finserve:</h4>
                            <ul>

                                <li className='dealer_text_para'><b>Improved Cash Flow: </b>
                                    Logistics financing provides immediate access to
                                    capital, allowing companies to address cash flow gaps and meet financial
                                    obligations without disrupting operations.
                                </li>
                                <li className='dealer_text_para'><b>Asset Ownership: </b>
                                    Financing enables logistics companies to acquire vehicles
                                    and equipment without tying up large sums of capital, preserving liquidity and
                                    financial flexibility for other strategic initiatives.
                                </li>
                                <li className='dealer_text_para'><b>Scalability: </b>
                                    Access to financing supports the scalability of logistics operations,
                                    allowing companies to respond quickly to changes in demand, expand into
                                    new markets, or pursue strategic opportunities.
                                </li>
                                <li className='dealer_text_para'><b>Long-term Planning: </b>
                                    With stable financing in place, logistics companies can
                                    focus on long-term planning, investment, and innovation, rather than being
                                    constrained by short-term cash flow challenges.
                                </li>
                                <li className='dealer_text_para'><b>Customer Satisfaction: </b>
                                    Improved fleet reliability, on-time deliveries, and
                                    enhanced service quality resulting from financing investments contribute to
                                    higher customer satisfaction and retention rates.
                                </li>



                            </ul>

                            <p className='dealer_text_para'>In summary, logistics/transporter financing plays a vital role in supporting the growth,
                                resilience, and competitiveness of logistics companies by providing access to capital,
                                enabling investment in assets and technology, and mitigating financial risks inherent
                                in the industry
                                .</p>

                        </div>


                    </div>
                </div>
                <Solution_cards />

                <FloatingWhatsApp phoneNumber="919791028585" accountName="srini" />



                <Footer />
            </div>
        </>
    )
}

export default Transporter

import '../hamcss/contactform.css';
import '../hamcss/team.css';
import con from '../assests/cont.webp';
import { useRef, useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
const ContactForm = () => {
const emailRef = useRef();
const nameRef = useRef();
const phoneRef = useRef();
const messageRef = useRef();
const [loading, setLoading] = useState(false);
const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    const serviceId = "service_kck0ncu";
    const templateId = "template_vj6wakn";
    try {
    if(nameRef.current.value === "" || emailRef.current.value === "" || phoneRef.current.value === "" || messageRef.current.value === ""){
    alert('Please enter mandatory data!');
    }
    else if(!emailRegexp.test(emailRef.current.value)){
        alert('Please enter valid email!');
    }
    else if(phoneRef.current.value.length!=10){
        alert('Please enter valid phone number!');
    }
    else {
    
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
       name: nameRef.current.value,
       email: emailRef.current.value,
       phone: phoneRef.current.value,
       message: messageRef.current.value,
        recipient: emailRef.current.value
      });
      alert("Email successfully sent! Check your inbox!");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


    return (
        <>



            <div class="app-form">
            <form className="for" onSubmit={handleSubmit}>
                <div>
                    <h2 className="headings">Contact form</h2>
                    <div class="app-form-group">
                        <input ref={nameRef} class="app-form-control" placeholder="NAME*" />
                    </div>
                    <div class="app-form-group">
                        <input ref={emailRef} class="app-form-control" placeholder="EMAIL*" />
                    </div>
                    <div class="app-form-group">
                        <input type="number" ref={phoneRef}class="app-form-control" placeholder="CONTACT NO*" />
                    </div>
                    <div class="app-form-group ">
                        <textarea ref={messageRef} class="app-form-control" placeholder="MESSAGE*" />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ marginTop: '10px', fontSize: '14px', fontWeight: '500', }} >
                        <input type="checkbox" checked="true"/> I declare that the information I have provided is accurate & complete to the best of my knowledge. I hereby authorize SG Finserve Ltd. and its affiliates to call, email, send a text through the Short Messaging Service (SMS) and/or WhatsApp  me in relation to any of their products. The consent herein shall override any registration for DNC/NDNC.
                    </div>
                </div>
                <div style={{ marginTop:'15px' }}>
                    <button class="app-form-button">SEND</button>
                </div>
            </form>
            </div>







        </>
    )
}

export default ContactForm
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Hamburger from './Hamburger';
import DotRing from '../component/dot/DotRing';
import '../hamcss/navbar.css';


const Navbar = () => {



    const [hovered, setHovered] = useState(false);
    const [hovered1, setHovered1] = useState(false);

    let hoverTimer;

    const handleMouseEnter = () => {
        clearTimeout(hoverTimer);
        setHovered(true);
        setHovered1(false)
    };

    const handleMouseEnter1 = () => {
        clearTimeout(hoverTimer);
        setHovered1(true);
        setHovered(false)
    };

    const handleMouseLeave = () => {
        hoverTimer = setTimeout(() => {
            setHovered(false);
        }, 1000);
    };

    const handleMouseLeave1 = () => {
        hoverTimer = setTimeout(() => {
            setHovered1(false);
        }, 1000);
    };


    const [navbarStyle, setNavbarStyle] = useState({
        borderRadius: '20px',
        position: 'fixed',
        marginTop: '10px',
        width: '1300px',
        backgroundColor: 'rgba(247, 248, 250, 0.8)',
        zIndex: 1000,
        height: '75px',
        transition: 'all 0.3s ease-in-out',

        // '@media (min-width: 1000px)': {
        //     width: '500px',
        //     borderRadius: '0',
        //     marginTop: '0',
        //     height: '60px',
        // }

    }); // Initial navbar style

    useEffect(() => {
        let prevScrollPos = window.pageYOffset;

        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (prevScrollPos < currentScrollPos) {
                setNavbarStyle({
                    ...navbarStyle,
                    borderRadius: '0px',
                    width: '100%',
                    height: '75px',
                    marginTop: '0px',
                    top: '0px',
                    transition: 'all 0.3s ease-in-out',
                    backgroundColor: 'rgba(247, 248, 250, 1)',


                });

            } else {
                setNavbarStyle({
                    ...navbarStyle,
                    borderRadius: '20px',
                    top: '5px',
                });
            }
            prevScrollPos = currentScrollPos;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);






    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            window.scrollTo({
                behavior: 'smooth',
                top: section.offsetTop,
            });
        }
    };





    return (


        <>

            <DotRing />


            <section className='main-navbar-container'>
                <div style={navbarStyle} className="navbar-area ">

                    <div className="main-navbar">
                        <div className="container">
                            <nav style={{ display: 'flex', justifyContent: 'space-between' }} className="navbar navbar-expand-md navbar-light">

                                <Link to={'/'} className="navbar-brand">

                                    <img src="/images/logo.webp" alt="sg-finserve logo" style={{ width: '70px', height: '45px' }} />
                                </Link>


                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>

                                    <div>
                                        <ul style={{ marginBottom: '10px' }} className="navbar-nav">
                                            <li className="nav-item">
                                                <Link to={'/'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                    Home
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link
                                                    onMouseEnter={handleMouseEnter1}
                                                    onMouseLeave={handleMouseLeave1}
                                                    to={'/aboutUs'}
                                                    style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }}
                                                    className="nav-link"
                                                >
                                                    <div style={{ display: 'flex', alignContent: 'center', gap: '6px' }}>
                                                        About us <i className="fa fa-angle-down slide-know-btn"></i>
                                                    </div>
                                                </Link>

                                                {hovered1 && (
                                                    <div
                                                        onMouseEnter={handleMouseEnter1}
                                                        onMouseLeave={handleMouseLeave1}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '52px',
                                                            left: '-150px',
                                                            padding: '15px',
                                                            width: '420px',
                                                            height: 'fit-content',
                                                            backgroundColor: 'rgba(247, 248, 250, 0.9)',
                                                            display: 'flex',
                                                            gap: '10px',
                                                            borderRadius: '0 0 10px 10px',
                                                            boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)',
                                                        }}
                                                    >
                                                        <ul style={{ display: 'flex', flexDirection: 'column' }} className="navbar-nav">

                                                            <li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=board" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none', paddingLeft: '10px' }} className="nav-link">
                                                                    Board of directors
                                                                </Link>
                                                            </li>

                                                            <li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=team" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Experienced team
                                                                </Link>
                                                            </li>

                                                            <li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=bank" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Banking Partners
                                                                </Link>
                                                            </li>

                                                            <li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=business" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Business Partners
                                                                </Link>
                                                            </li>
                                                        </ul>

                                                        <ul style={{ display: 'flex', flexDirection: 'column' }} className="navbar-nav">


                                                            <li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=life" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none', paddingLeft: '10px' }} className="nav-link">
                                                                    Life@SG Finserve
                                                                </Link>
                                                            </li>

                                                           {/* <li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=mission" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Mission/Vision/Values
                                                                </Link>
                                                            </li>

                                                            <li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=finance" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Financial position
                                                                </Link>
                                                            </li>*/}

                                                            {/*<li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=solution" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Our Solutions
                                                                </Link>
                                                            </li>*/}

                                                            {/* <li className="nav-item">
                                                                <Link to="/aboutUs?scrollTo=priority" style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Focus Priority
                                                                </Link>
                                                            </li> */}

                                                        </ul>
                                                    </div>
                                                )}

                                            </li>

                                            <li style={{ position: 'relative' }} className="nav-item">
                                                <Link
                                                    onMouseEnter={handleMouseEnter}
                                                    onMouseLeave={handleMouseLeave}
                                                    style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }}
                                                    className="nav-link"
                                                >
                                                    <div style={{ display: 'flex', alignContent: 'center', gap: '6px' }}>
                                                        Solution <i className="fa fa-angle-down slide-know-btn"></i>
                                                    </div>
                                                </Link>
                                                {hovered && (
                                                    <div
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '52px',
                                                            left: '-80px',
                                                            padding: '15px',
                                                            width: '305px',
                                                            height: 'fit-content',
                                                            backgroundColor: 'rgba(247, 248, 250, 0.9)',
                                                            borderRadius: '0 0 10px 10px',
                                                            boxShadow: '0px 0px 5px 2px rgba(0,0,0,0.1)',
                                                        }}
                                                    >
                                                        <ul style={{ display: 'flex', flexDirection: 'column' }} className="navbar-nav">

                                                            <li className="nav-item">
                                                                <Link to={'/dealer'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none', paddingLeft: '10px' }} className="nav-link">
                                                                    Dealer financing
                                                                </Link>
                                                            </li>

                                                            <li className="nav-item">
                                                                <Link to={'/retailer'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Retailer financing
                                                                </Link>
                                                            </li>

                                                            <li className="nav-item">
                                                                <Link to={'/vender'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Vendor financing
                                                                </Link>
                                                            </li>

                                                            <li className="nav-item">
                                                                <Link to={'/transport_logistics'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Logistics / transporter financing
                                                                </Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                <Link to={'/term-loan'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                                    Tailor Made Term Lending Solutions
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                )}
                                            </li>




                                            <li className="nav-item">
                                                <Link to={'/investor'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                    Investor Relations
                                                </Link>
                                            </li>


                                            <li className="nav-item">
                                                {/* <Link to={'/career'} style={{ color: '#2C2C2C', fontWeight: '600', fontSize: '16px' }} className="nav-link">
                                                Careers
                                            </Link> */}
                                                <Link to={'/csr-initiative'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                    CSR Initiative
                                                </Link>
                                            </li>


                                            <li className="nav-item">
                                                <Link to={'/blogs'} style={{ color: '#2C2C2C', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none' }} className="nav-link">
                                                    Blog
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to={'/contact'} style={{ color: 'white', fontWeight: '600', letterSpacing: '0.5px', textTransform: 'none', backgroundColor: '#1961ac', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '8px' }} className="nav-link">
                                                    Contact Us
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>


                                    <div className="info-contact">
                                        <i className="fa fa-phone"></i>
                                        <span style={{ fontWeight: '600' }}>
                                            <a href="tel:+91-9205556122">+91-9205556122</a>

                                        </span>

                                        <div className="info-contact">
                                            
                                            <span style={{ fontWeight: '600' }}>CIN:L64990DL1994PLC057941</span><br />
                                        </div> 

                                    </div>

                                </div>

                            </nav>

                        </div>
                    </div>

                </div>

            </section>



            <div style={{ backgroundColor: 'rgba(255,255,255)', position: 'absolute', zIndex: 5, top: 0, width: '100%' }} className="main-responsive-nav">
                <div className="container">
                    <div className="col-sm-4">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="main-responsive-menu ">
                            <div className="logo">
                                <Link to={'/'}>
                                    <img src="/images/logo.webp" alt="image" />
                                </Link>
                            </div>
                            <Hamburger />
                        </div>
                    </div>
                </div>
            </div>




        </>











    );
};

export default Navbar;

import '../../hamcss/position.css';
import networth from '../../assests/icons/business_10566716.png';
import Ratio from '../../assests/icons/float_9286049.png';
import credit from '../../assests/icons/credit-score_14597854.png';
import Banking from '../../assests/icons/clipboard_1153890.png';
import Business from '../../assests/icons/bar-chart_190766.png';
import { FaHandHoldingUsd } from "react-icons/fa";
import { CiPercent } from "react-icons/ci";
import { BsCreditCard2Front } from "react-icons/bs";
import { CiBank } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import { MdOutlineBusiness } from "react-icons/md";




import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';


const PositionText = () => {
    return (

        <>
            <section className='main-position-box'>
                <div style={{ textAlign: 'left' }}>
                    <h2 className='h2-text-posi' style={{ fontWeight: '600', wordSpacing: "2px", letterSpacing: '0.2px' }}>STRONG FINANCIAL POSITION </h2>

                    <div className='main-card-box-posi'>

                        <div className="container-position">
                            <div className="card-position">
                                <div className="image">
                                    {/* <img src={networth} alt="networth icon" /> */}
                                    <div style={{ display: 'flex', alignItems: '', justifyContent: 'center', paddingBottom: "50px" }}>
                                        <FaHandHoldingUsd size={120} />
                                    </div>

                                    <h5 className='h5-text-posi'>AUM and Networth</h5>
                                </div>
                                <div className="content-posi">
                                    {/* <h3>Networth</h3> */}
                                    <p className='p-text-posi'>
                                        Company’s outstanding loan book and net worth as on 31st December 2024 stood at 1568 crores and 989 crores respectively.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container-position">
                            <div className="card-position">
                                <div className="image">
                                    {/* <img src={Ratio} alt="Strong Leverage Ratio" /> */}

                                    <div style={{ display: 'flex', alignItems: '', justifyContent: 'center', paddingBottom: "50px" }}>
                                        <CiPercent size={120} />
                                    </div>

                                    <h5 className='h5-text-posi' >Geographical Diversification</h5>
                                </div>
                                <div className="content-posi">
                                    {/* <h3>Strong Leverage Ratio</h3> */}
                                    <p className='p-text-posi'>
                                        Diversified portfolio presence across the country thus ensuring mitigation of regional economic risks, ensuring stable growth despite localized challenges.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container-position">
                            <div className="card-position">
                                <div className="image">
                                    {/* <img src={credit} alt="Strong Credit Rating" /> */}

                                    <div style={{ display: 'flex', alignItems: '', justifyContent: 'center', paddingBottom: "50px" }}>
                                        <BsCreditCard2Front size={120} />
                                    </div>

                                    <h5 className='h5-text-posi'>Strong Credit Rating</h5>
                                </div>
                                <div className="content-posi">
                                    {/* <h3>Strong Credit Rating</h3> */}
                                    <p className='p-text-posi'>
                                        Robust financial position is further underscored by CRISIL AA (CE) /ICRA AA/A1+ (CE) rating bestowed upon the borrowings of the Company, affirming its financial strength.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="container-position">
                            <div className="card-position">
                                <div className="image">
                                    {/* <img src={Banking} alt="Banking" /> */}

                                    <div style={{ display: 'flex', alignItems: '', justifyContent: 'center', paddingBottom: "50px" }}>
                                        <CiBank size={120} />
                                    </div>

                                    <h5 className='h5-text-posi'>Satisfactory Banking Conduct</h5>

                                </div>
                                <div className="content-posi">
                                    {/* <h3>Satisfactory Banking Conduct</h3> */}
                                    <p className='p-text-posi'>
                                        Company has strong liquidity in the form of Equity from Promoters/ HNIs/ Funds and debt from various large Banks and Financial Institutions of the country. It has always serviced its obligations in time with no instances of delay.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="container-position">
                            <div className="card-position">
                                <div className="image">
                                    {/* <img src={Business} alt="Business" /> */}


                                    <div style={{ display: 'flex', alignItems: '', justifyContent: 'center', paddingBottom: "50px" }}>
                                        <MdOutlineBusiness size={120} />
                                    </div>

                                    <h5 className='h5-text-posi'>Established Business Model</h5>
                                </div>
                                <div className="content-posi">
                                    {/* <h5>Profitable Business Model</h5> */}
                                    <p className='p-text-posi'>
                                        Company has a well established business model with 40 anchor tie ups and borrowers around across the country.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="container-position">
                            <div className="card-position">
                                <div className="image">
                                    {/* <img src={Business} alt="Business" /> */}


                                    <div style={{ display: 'flex', alignItems: '', justifyContent: 'center', paddingBottom: "50px" }}>
                                        <CiYoutube size={120} />
                                    </div>

                                    <h5 className='h5-text-posi'>Digitalization</h5>
                                </div>
                                <div className="content-posi">
                                    {/* <h5>Profitable Business Model</h5> */}
                                    <p className='p-text-posi'>
                                        Adoption of digital technology to enhanced operational efficiency, cost reduction and improved collection rates, thereby boosting profitability.
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>













            </section>



            <section style={{ marginBottom: "40px" }} className='main-position-box-mobile'>
                <div style={{ textAlign: 'left' }}>
                    <h2 className='h2-text-posi' style={{ fontWeight: '600' }}>STRONG <span style={{ color: '#17a9b5' }}> FINANCIAL POSITION </span></h2>
                    <Swiper
                        slidesPerView={1}
                        autoplay={{ delay: 3000 }}
                        modules={[Pagination, Autoplay]}

                    >
                        <div className='main-card-box-posi-mobi'>

                            <SwiperSlide>
                                <div className="container-position-mobi">
                                    <div className="card-position-mobi">
                                        <div className="image">
                                            <img src={networth} alt="networth icon" />
                                            {/* <h5 className='h5-text-posi'>Networth</h5> */}
                                        </div>
                                        <div className="content-posi">
                                            <h3>Networth</h3>
                                            <p className='p-text-posi'>
                                                Company’s Net worth as on 30th Sep 2023 stood at 758.96 crores.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>

                            <SwiperSlide>

                                <div className="container-position-mobi">
                                    <div className="card-position-mobi">
                                        <div className="image">
                                            <img src={Ratio} alt="networth icon" />
                                            {/* <h5 className='h5-text-posi'>Networth</h5> */}
                                        </div>
                                        <div className="content-posi">
                                            <h3>Strong Leverage Ratio- </h3>
                                            <p className='p-text-posi'>
                                                Leverage Ratio of the Company stands at a commendable 1:1, reflecting a prudent and secure approach to managing financial liabilities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="container-position-mobi">
                                    <div className="card-position-mobi">
                                        <div className="image">
                                            <img src={credit} alt="networth icon" />
                                            {/* <h5 className='h5-text-posi'>Networth</h5> */}
                                        </div>
                                        <div className="content-posi">
                                            <h3>Strong Credit Rating </h3>
                                            <p className='p-text-posi'>
                                                Robust financial position is further underscored by the CRISIL AA (CE) /Positive rating bestowed upon the borrowings of the Company, affirming its financial strength.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="container-position-mobi">
                                    <div className="card-position-mobi">
                                        <div className="image">
                                            <img src={Banking} alt="networth icon" />
                                            {/* <h5 className='h5-text-posi'>Networth</h5> */}
                                        </div>
                                        <div className="content-posi">
                                            <h3>Satisfactory Banking Conduct </h3>
                                            <p className='p-text-posi'>
                                                Company has been availing Credit Limits since December 2023, and has serviced all loan obligations- repayments and interest on time with no instances of delay.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>


                            <SwiperSlide>
                                <div className="container-position-mobi">
                                    <div className="card-position-mobi">
                                        <div className="image">
                                            <img src={Business} alt="networth icon" />
                                            {/* <h5 className='h5-text-posi'>Networth</h5> */}
                                        </div>
                                        <div className="content-posi">
                                            <h3>Profitable Business Model- </h3>
                                            <p className='p-text-posi'>
                                                Company has established a profitable business model achieving PAT of INR 18.4 crores in FY2023 and INR 33.13 crores in the 1st Half of FY2024.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>


                        </div>
                    </Swiper>
                </div>













            </section>






        </>
    )
}

export default PositionText
import React from 'react'
import '../../hamcss/solution_card.css';
import dealer from '../../assests/cardImage/dealer.webp';
import dealer2 from '../../assests/cardImage/handshake-4011419_640.webp';
import Reatiler from '../../assests/cardImage/retailer.webp';
import Tailor from '../../assests/cardImage/loan.webp';
import vendor from '../../assests/cardImage/vendor.webp';
import logist from '../../assests/cardImage/logistic.webp';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';


const Solution_cards = () => {


    useEffect(() => {
        AOS.init();

    })

    document.addEventListener('DOMContentLoaded', function () {
        var card = document.querySelector('.card');

        card.addEventListener('mouseover', function () {
            var headingText = document.querySelector('.dealer_heading_text');
            headingText.classList.add('hide-text');
        });

        card.addEventListener('mouseout', function () {
            var headingText = document.querySelector('.dealer_heading_text');
            headingText.classList.remove('hide-text');
        });
    });


    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };




    return (
        <>

            <div className='main_solution_card_container'>
                <h2 className='solution_heading_text'>Explore Our  Solutions  </h2>

                <div className='child_dealer_container'>

                    <div data-aos="zoom-out" data-aos-duration='900' style={{ backgroundImage: `url(${dealer})`, backgroundRepeat: 'no-repeat' }} class="card x_card_">
                        <div class="content expand-button">
                            <div style={{ marginBottom: '90px' }}>
                                {/* <h5 style={{ fontSize: '18px', marginRight: '140px', marginBottom: '20px' }}>Loan Terms</h5> */}
                                <p style={{ fontSize: '18px', color: 'white', fontWeight: '400', textAlign: 'left' }} className='card_content_text'>Empowering Corporates to Support Their Dealers/Distributors to Catalyse Sales. </p>
                            </div>
                            <Link to={'/dealer'}> <button onClick={handleLinkClick} className='know-more-btn'>Know More</button></Link>
                        </div>
                        <h5 class="dealer_heading_text">Dealer Financing</h5>
                    </div>



                    <div data-aos="zoom-out" data-aos-duration='1300' style={{ backgroundImage: `url(${vendor})` }} class="card x_card_">
                        <div class="content expand-button">
                            <div style={{ marginBottom: '90px' }}>
                                {/* <h5 style={{ fontSize: '18px', marginRight: '140px', marginBottom: '20px' }}>Loan Terms</h5> */}
                                <p style={{ fontSize: '18px', color: 'white', fontWeight: '400', textAlign: 'left' }} className='card_content_text'>Empowering Corporates to Support Their Vendors/Suppliers for More Efficient Supply Chain </p>
                            </div>
                            <Link to={'/vender'}> <button onClick={handleLinkClick} className='know-more-btn'>Know More</button></Link>
                        </div>
                        <h5 class="dealer_heading_text">Vendor Financing</h5>
                    </div>



                    <div data-aos="zoom-out" data-aos-duration='1500' style={{ backgroundImage: `url(${logist})` }} class="card x_card_">
                        <div class="content expand-button">
                            <div style={{ marginBottom: '90px' }}>
                                {/* <h5 style={{ fontSize: '18px', marginRight: '140px', marginBottom: '20px' }}>Loan Terms</h5> */}
                                <p style={{ fontSize: '18px', color: 'white', fontWeight: '400', textAlign: 'left' }} className='card_content_text'>Logistics/ Transport Finance sits at the heart of our end-to-end Logistics offering, supporting & empowering transport companies. </p>
                            </div>
                            <Link to={'/transport_logistics'}> <button onClick={handleLinkClick} className='know-more-btn'>Know More</button></Link>
                        </div>
                        <h5 class="dealer_heading_text">Logistics/ Transporter Financing</h5>
                    </div>


                    <div data-aos="zoom-out" data-aos-duration='1100' style={{ backgroundImage: `url(${Reatiler})` }} class="card ">
                        <div class="content expand-button">
                            <div className='content_box-card' >
                                {/* <h5 className='heading_card_content' >Loan Terms</h5> */}
                                <p style={{ fontSize: '18px', color: 'white', fontWeight: '400', textAlign: 'left' }} className='card_content_text'>Tailoring financing solution to the lowest possible tier in a seamless manner. </p>
                            </div>
                            <Link to={'/retailer'}> <button onClick={handleLinkClick} className='know-more-btn'>Know More</button></Link>
                        </div>
                        <h5 class="dealer_heading_text">Retailer Financing</h5>
                    </div>
                    <div data-aos="zoom-out" data-aos-duration='1100' style={{ backgroundImage: `url(${Tailor})` }} class="card ">
                        <div class="content expand-button">
                            <div className='content_box-card' >
                                {/* <h5 className='heading_card_content' >Loan Terms</h5> */}
                             <p style={{ fontSize: '18px', color: 'white', fontWeight: '400', textAlign: 'left' }} className='card_content_text'>Term Loan </p>   
                            </div>
                            <Link to={'/term-loan'}> <button className='know-more-btn'>Know More</button></Link>
                        </div>
                        <h5 class="dealer_heading_text">Tailor Made Term Lending Solutions</h5>
                    </div>







                </div>





                {/* for mobile */}

                <div className='mobile_child_dealer_container'>

                    <div className='mobile_child'>
                        <div style={{ backgroundImage: `url(${dealer})` }} class="card_mobile ">

                            <Link to={'/dealer'}>   <h5 onClick={handleLinkClick} class="mobile_dealer_heading_text">  Dealer Financing </h5></Link>
                        </div>

                        <div style={{ backgroundImage: `url(${Reatiler})` }} class="card_mobile ">

                            <Link to={'/retailer'}><h5 onClick={handleLinkClick} class="mobile_dealer_heading_text">  Retailer Financing </h5></Link>
                        </div>

                    </div>



                    <div className='mobile_child'>
                        <div style={{ backgroundImage: `url(${dealer})` }} class="card_mobile">

                            <Link to={'/vender'}>   <h5 onClick={handleLinkClick} class="mobile_dealer_heading_text">  Vender Financing </h5></Link>
                        </div>



                        <div style={{ backgroundImage: `url(${Reatiler})` }} class="card_mobile">

                            <Link to={'/transport_logistics'}> <h5 onClick={handleLinkClick} class="mobile_dealer_heading_text">  Logistics/ Transporter Financing  </h5></Link>
                        </div>
                    </div>
                    <div className='mobile_child'>
                        <div style={{ backgroundImage: `url(${Tailor})` }} class="card_mobile">

                            <Link to={'/term-loan'}>   <h5 onClick={handleLinkClick} class="mobile_dealer_heading_text">  Tailor Made Term Lending Solutions </h5></Link>
                        </div>



                        
                    </div>

                </div>


            </div >











        </>
    )
}

export default Solution_cards
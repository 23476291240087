import React from "react";
import { useRef, useEffect, useState } from "react";
import AOS from "aos";
import Navbar from "./Navbar";
import Footer from "./Footer";
import contactBanner from "../assests/contact/contact.webp";
import Careers from '../component/Careers';

import { FloatingWhatsApp } from 'react-floating-whatsapp';
import DotRing from '../component/dot/DotRing';
import '../hamcss/contact.css';
import ContactForm from "./ContactForm";
import bg from '../assests/contact/contactBG.webp';
import '../hamcss/contact.css';

import emailjs from "@emailjs/browser";

const Contact = () => {
  useEffect(() => {
    AOS.init();
    emailjs.init("ofUOOYpRaQBHilb5C");
  });
const [loading, setLoading] = useState(false);
  return (

    <>


      <div>
        <Navbar />
        <DotRing />

        {/* <!--start banner slider--> */}
        <section>
          <div
            className="BreadArea"
            style={{
              backgroundImage: `url(${contactBanner})`,
              // backgroundImage: "url('images/Bread.png')",
            }}
          >
            <div className="container">

              <h2 className="shadows">
                <span>C</span>
                <span>O</span>
                <span>N</span>
                <span>T</span>
                <span>A</span>
                <span>C</span>
                <span>T</span>
                <span>U</span>
                <span>S</span>
              </h2>

            </div>
          </div>
        </section>
      </div>

      <section style={{ display: 'flex', justifyContent: 'center', }}>
        <section className="containner">

          <section className='main-wraper-cl' >
            <div className="main-con-cl">
            <ContactForm />

              <div className="col-sm-4">
              <h3 className="headings">Contact Information</h3>
              <div class="single-footer-widget" >
              <div className="info-contact">
                                    <i className="fa fa-phone"></i>
                                    <span>
                                        <a href="tel:+91 120-4041400">+91-120-4041400</a>
                                    </span>
                                    <br />
                                    <span>
                                        <a href="tel:+91-11-22373437">+91-11-22373437</a>
                                    </span>

                                </div>
                                <div className="info-contact">
                                    <i className="fa fa-envelope"></i>
                                    <span>info@sgfinserve.com</span>
                                </div>
                                <div className="info-contact">
                                    <i className="fa fa-map-marker"></i>
                                    <span>35-36, Kaushambi Near Anand Vihar Terminal Ghaziabad Uttar Pradesh 201010
                                        (India).</span><br />
                                    <span>37, Hargobind Enclave Vikas Marg New Delhi 110092 (India).</span>
                                    
                                </div>
                                </div>
               {/* <div className="two-b">
                  <div className="con-card-cl">
                    <img data-aos="fade-up" data-aos-duration="1000" src="/images/phone.png" alt='contact' />
                    <h5 > Phone Number</h5>
                    <p style={{ fontSize: "18px" }} >+91-120-4041400 <br /> +91-11-22373437</p>
                  </div>



                  <div className="con-card-cl">
                    <img src="/images/mail.png" alt='contact' />
                    <h5 data-aos="fade-up" data-aos-duration="1000">
                      Email Id
                    </h5>
                    <p style={{ fontSize: "18px" }}>
                      info@sgfinserve.com
                    </p>
                  </div>

                </div>

                <div className="two-b">
                  <div className="con-card-cl" >
                    <img src="/images/map-marker.png" alt='contact' />
                    <h5 data-aos="fade-up" data-aos-duration="1000">   Our Address</h5>
                    <h6 style={{ fontSize: "18px" }}>Corporate office</h6>
                    <p style={{ fontSize: "18px" }}>
                      35, 36 Kaushambi, Near Anand Vihar Terminal,
                      Ghaziabad, Uttar Pradesh 201010
                    </p>
                  </div>



                  <div className="con-card-cl" >
                    <img src="/images/map-marker.png"
                      alt='contact'
                    />
                    <h5 >  Our Address </h5>
                    <h6 style={{ fontSize: "18px" }} >  Registered office </h6>
                    <p style={{ fontSize: "18px" }} >
                      37, Hargobind Enclave Vikas Marg New Delhi 110092 (India)
                    </p>
                  </div>

                </div>
                */}
              </div>

              

            </div>




          </section>






        <section className='main-wraper-conv' >
            <div className="main-con-cl">
                <div class="col-sm-3 conv">
                    <h3 className="headings">Debenture Trustee</h3>
                    <div class="single-footer-widget" >
                        <div className="info-conv">
                          
                            Name : <span>
                             Axis Trustee Services Limited
                            </span>
                            

                        </div>
                        <div className="info-conv">

                        
                        Registered Office : <span>
                             Axis House, Bombay Dyeing Mills Compound, Pandurang Budhkar Marg, Worli, Mumbai City, Maharashtra, India, 400025 
                        </span>
                        

                        </div>
                        <div className="info-conv">

                        
                        Corporate Office : <span>
                             The Ruby, 2nd Floor, SW 29, Senapati Bapat Marg, Dadar West, Mumbai, Maharashtra, India, 400028 
                        </span>
                        

                        </div>
                        <div className="info-conv">

                        
                        Contact Person : <span>
                             Chief Operation Officer
                        </span>
                        

                        </div>
                        <div className="info-conv">

                        
                        Tel : <span>
                                91-22-62300451 
                        </span>
                        

                        </div>
                        <div className="info-conv">

                        
                        Email : <span>debenturetrustee@axistrustee.in
                        </span>
                        

                        </div>
                        <div className="info-conv">

                        
                        Website : <span>
                                www.axistrustee.in
                        </span>
                        

                        </div>
                    
                    </div>
                </div>

                <div className="col-sm-3 conv">
                    <h3 className="headings">RTA</h3>
                    <div class="single-footer-widget" >
                        <div className="info-conv">
                            Name : <span>
                              Skyline Financial Services Private Limited
                            </span>
                        </div>
                        <div className="info-conv">
                            Address : <span>
                             D-153 A, Ist Floor, Okhla Industrial Area, Phase - I New Delhi-110 020
                            </span>
                        </div>
                        <div className="info-conv">
                            Tel : <span>
                                011-26812682-83, 40450193 to 97
                            </span>
                        </div>
                        <div className="info-conv">
                            Mobile : <span>
                                9999589742
                            </span>
                        </div>
                        <div className="info-conv">
                            Website : <span>
                                www.skylinerta.com
                            </span>
                        </div>
                        <h3><center>Contact Person</ center></ h3>
                        <div className="info-conv">
                            Name : <span>
                                    Sarvesh Kumar
                            </span>
                        </div>
                        <div className="info-conv">
                            Mobile : <span>
                                    9899576033
                            </span>
                        </div>
                        <div className="info-conv">
                            Tel : <span>
                                    01140450193
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-sm-3 conv">
                    <h3 className="headings">CUSTOMERS COMPLAINTS</h3>
                    <div class="single-footer-widget" >
                        <div className="info-contact">
                        <i className="fa fa-user"></i>
                        <span>
                            Mr. Anshul Manchanda - Grievance Redressal Officer
                        </span>
                        </div>
                        <div className="info-contact">
                        <i className="fa fa-phone"></i>
                        <span>
                            <a href="tel:8586944917">8586944917</a>
                        </span>
                        </div>
                        <div className="info-contact">
                          <i className="fa fa-envelope"></i>
                          <span>anshul.manchanda@sgfinserve.com</span>
                        </div>
                        <div className="info-contact">
                          <span>(From 10.00 am To 6:00 pm, Monday – Friday, except on public holidays)</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 conv">
                    <h3 className="headings">INVESTORS COMPLAINTS</h3>
                    <div class="single-footer-widget" >
                        <div className="info-contact">
                        <i className="fa fa-user"></i>
                        <span>
                            Ms. Ritu Nagpal - Company Secretary & Compliance Officer
                        </span>
                        </div>
                        <div className="info-contact">
                        <i className="fa fa-phone"></i>
                        <span>
                            <a href="tel:0120-4041400">0120-4041400</a>
                        </span>
                        </div>
                        <div className="info-contact">
                          <i className="fa fa-envelope"></i>
                          <span>compliance@sgfinserve.com</span>
                        </div>
                        <div className="info-contact">
                          <span>(From 10.00 am To 6:00 pm, Monday – Friday, except on public holidays)</span>
                        </div>
                    </div>
                </div>


            </div>
        </section>

          {/* <div>
          <ReactWhatsapp number="+919791028585" message="Hello World!!!" />
          <FloatingWhatsApp phoneNumber="919791028585" accountName="srini" />
        </div> */}

          <Footer />


        </section>
      </section >
    </>
  );
};

export default Contact;

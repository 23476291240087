import React from 'react'
import '../../hamcss/bussiness.css';
import { useEffect } from 'react';

import p from '../../assests/bank Logos/p.png';
import p1 from '../../assests/bank Logos/p1.png';
import p2 from '../../assests/bank Logos/p2.png';
import p3 from '../../assests/bank Logos/p3.png';
import p4 from '../../assests/bank Logos/p4.jpg';
// import p5 from '../../assests/bank Logos/p5.png';
import p6 from '../../assests/bank Logos/p6.png';
// import p7 from '../../assests/bank Logos/p7.png';
import p8 from '../../assests/bank Logos/p8.png';
import p9 from '../../assests/bank Logos/p9.png';
import p10 from '../../assests/bank Logos/p10.webp';
import p11 from '../../assests/bank Logos/p11.png';
import br from '../../assests/bank Logos/br.jpg';
import au from '../../assests/bank Logos/au.png';
import tata from '../../assests/bank Logos/tata.webp';
import AOS from 'aos';



const BankingPartners = () => {



    const images = [
         p4, p1, br, p8, p11, p10, p, au
    ];



    useEffect(() => {
        AOS.init();

    })


    return (
        <>

            <section className='main-bussiness'>
                <div>
                    <h2 className='h2-text-b'>Our Banking Partners </h2>
                </div>

                <div className="business-parners-box">
                    {images.map((image, index) => (
                        <div data-aos="flip-up" data-aos-duration='800' className="image-box-busi" key={index}>
                            <img src={image} alt='partners logo' />
                        </div>
                    ))}
                </div>

            </section>

        </>
    )
}

export default BankingPartners











import React from 'react'

import careerBanner from '../assests/career/career.webp';
import Navbar from './Navbar';
import Footer from './Footer';


import DotRing from '../component/dot/DotRing';
import { Link } from 'react-router-dom';
// import au from '../assests/Team/abhishek mahajan.png';

const Careers = () => {
    return (
        <>
            <section style={{  justifyContent: 'center' }}>
                <section className="containner">
                    <DotRing />

                    <Navbar />
                    <div>
                        <div className="BreadArea" style={{
                            backgroundImage: `url(${careerBanner})`,
                        }}>
                            <div
                                className="container">
                                <h2>Career</h2>
                            </div>
                        </div>
                    </div>

                    <h4 style={{ textAlign: 'center' }}>Updating soon</h4>
                    <Footer />
                </section>
            </section>

        </>

    )
}

export default Careers
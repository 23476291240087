import React from 'react'
import Navbar from '../Navbar';
import Solution_cards from '../solution/Solution_cards';
import Footer from '../Footer';
import '../../hamcss/dealer.css';
import { useEffect } from 'react';
import AOS from 'aos';
import dealerBanner from '../../assests/home/top_banner/dealerImage.webp';

// import ReactWhatsapp from "react-whatsapp";
import { FloatingWhatsApp } from 'react-floating-whatsapp';



const TermLoan = () => {


  useEffect(() => {
    AOS.init();

  })


  return (
    <>

      <div className='main_dealer_container' >
        <Navbar />
        {/* <!--start banner slider--> */}
        <section>
          <div className="BreadArea" style={{
            backgroundImage: `url(${dealerBanner})`,
            objectFit: 'cover',
            // objectFit: 'fill'
          }}>
            <div className="container">
              <h2 style={{ wordSpacing: "2px", letterSpacing: '0.3px' }}>Tailor Made Term Lending Solutions</h2>

            </div>
          </div>
        </section>
        <div data-aos="fade-up" data-aos-duration="800" className='dealer_child_box' >
          <div className='dealer_text_container'>
            
            {/* <h5 className='dealer_text_heading'>Empowering Corporates to Support Their Dealers/Distributors to Catalyse Sales.</h5> */}
            <p style={{ }} className='dealer_text_para'>
              Collateral backed term loans are offered by SG Finserve, as a financial arrangement wherein a business entity secures a loan by pledging a property, typically an immovable asset. The loan amount is generally a percentage of the property's market value, and the funds obtained can be utilized for operational expenses or any other business-related purposes.
            </p>

            <p style={{  }} className='dealer_text_para'>Similarly, without collateral term loans by SG Finserve offer essential credit to SMEs/ MSMEs without the hassle of collateral. These loans offer flexible financing options tailored to meet specific business needs, such as expanding operations and managing working capital.
            </p>

           
          </div>
        </div>
        <Solution_cards />


        {/* <ReactWhatsapp number="+919791028585" message="Hello World!!!" /> */}
        <FloatingWhatsApp phoneNumber="919791028585" accountName="Customer Care" />



        <Footer />
      </div>

    </>

  )
}

export default TermLoan
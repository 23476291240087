import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import investerBanner from '../../assests/investor relationship/handshake.webp';
import '../../hamcss/investor.css';
import { useState } from 'react'
import { FaRegFilePdf } from "react-icons/fa";
import fy24_25 from '../../assests/sgPdf/familairizationofindependentprogramme/familarization 2024-25.pdf';
import fy23_24 from '../../assests/sgPdf/familairizationofindependentprogramme/FY 2023-24.pdf';
import fy22_23 from '../../assests/sgPdf/familairizationofindependentprogramme/FY 2022-23.pdf';
import fy21_22 from '../../assests/sgPdf/familairizationofindependentprogramme/FY 2021-22.pdf';
import fy20_21 from '../../assests/sgPdf/familairizationofindependentprogramme/FY 2020-21.pdf';


const DetailsFamilairization = () => {

    const [selectedOption, setSelectedOption] = useState("option-"); // State to track selected option

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value); // Update selected option when changed
    };


    return (
        <>


            <Navbar />
            <section>
                <div className="BreadArea" style={{

                    backgroundImage: `url(${investerBanner})`,
                }}>
                    <div className="container">
                        <h2>Details of Familairization</h2>
                    </div>
                </div>
            </section>


            <section className="main-an">

                <div className="main-an-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h2 className='h2-text'>Details of Familairization</h2>
                        <div>
                            <select className="select-class" onChange={handleOptionChange}>
                                <option value="option-">FY 2024-25</option>
                                <option value="option">FY 2023-24</option>
                                <option value="option1">FY 2022-23</option>
                                <option value="option2">FY 2021-22</option>
                                <option value="option3">FY 2020-21</option>
                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        {selectedOption === "option-" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy24_25} target='blank'><h6>FY 2024-25.pdf</h6></a>
                                </div>

                            </>

                        )}
                        {selectedOption === "option" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy23_24} target='blank'><h6>FY 2023-24.pdf</h6></a>
                                </div>

                            </>

                        )}
                        {selectedOption === "option1" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy22_23} target='blank'><h6>FY 2022-23.pdf</h6></a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option2" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy21_22} target='blank'><h6>FY 2021-22.pdf</h6></a>
                                </div>
                            </>
                        )}
                        {selectedOption === "option3" && (
                            <>
                                <div className="pdf-clas">
                                    <FaRegFilePdf size={25} style={{ color: 'red' }} />
                                    <a href={fy20_21} target='blank'><h6>FY 2020-21.pdf</h6></a>
                                </div>
                            </>
                        )}

                    </div>
                </div>

            </section>



            <Footer />


        </>
    )
}

export default DetailsFamilairization